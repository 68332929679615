"use strict"

const PREFIX = 'tests'
const PRACTICAL = 'practical-tests'

export default {
  postAnswers(typeTest, data) {
    return axios.post(`${PREFIX}/${typeTest}/save`, data)
  },

  getParticipantQuestionTest() {
    return axios.get(`${PREFIX}/questions`)
  },

  getResult() {
    return axios.get(`${PREFIX}/result`)
  },

  getPracticalTest(idMd) {
    return axios.get(`${PRACTICAL}/${idMd}`)
  },

  postPrePracticalTestAnswer(idMd, data) {
    return axios.post(`${PRACTICAL}/${idMd}/pre-practical-test/save`, data)
  },

  postPracticalTestAnswer(idMd, data) {
    return axios.post(`${PRACTICAL}/${idMd}/post-practical-test/save`, data)
  },

  uploadPhotoPracticalTest(data) {
    return axios.post(`${PRACTICAL}/photo-upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
  },

  getFinalResult(idMd) {
    return axios.get(`${PRACTICAL}/${idMd}/final-result`)
  }
}